import React from "react";
import { Accordion } from "react-bootstrap";
import DataFilters from "../../components/data/filters";
import useTranslation from "../../hooks/useTranslations";
import { dataFilters, Link } from "../../style/theme";
import { useArrow } from "../../hooks/useArrow";

export default function TableFilters({ setFilters, filters, setCurrentPage }) {
  const { arrow, setIsDown } = useArrow();
  const tr = useTranslation();
  return (
    <Accordion
      css={{
        marginTop: "1rem",
      }}
    >
      <Accordion.Toggle
        as={Link}
        onClick={() => setIsDown((prevState) => !prevState)}
        variant="link"
        eventKey="0"
        css={{
          cursor: "pointer",
          backgroundColor: "#fff",
          borderBottom: "none",
        }}
      >
        <p css={{ margin: 0, marginBottom: "0.5rem" }}>
          {tr("Filter the data")}: {arrow}
        </p>
      </Accordion.Toggle>

      <Accordion.Collapse eventKey="0">
        <DataFilters
          setFilters={setFilters}
          filters={filters}
          style={dataFilters}
          setCurrentPage={setCurrentPage}
        />
      </Accordion.Collapse>
    </Accordion>
  );
}
