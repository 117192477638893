import React from "react";
import { rhythm } from "utils/typography";
import useLocale from "../../hooks/useLocale";
import LocalizedLink from "../localizedLink";
import { MdPlayArrow } from "react-icons/md";
import { FaCalendarMinus } from "react-icons/fa";
import { TiLocation } from "react-icons/ti";
import { colors } from "../../style/theme";
import { formatDate } from "../../utils/helper";
import useTranslations from "../../hooks/useTranslations";

export default function IncidentCard({ incident, setHoveredUnit }) {
  const { isAra, locale } = useLocale();
  const tr = useTranslations();
  const {
    id,
    title_en,
    title_ar,
    date: incident_date,
    location,
    incident_code: code,
  } = incident;

  return (
    <LocalizedLink to={`data/incidents/${code}`} state={{ incident: incident }}>
      <div
        css={{
          backgroundColor: colors.light,
          marginBottom: rhythm(1),
          direction: isAra ? "rtl" : "ltr",
          textAlign: isAra ? "right" : "left",
          padding: `${rhythm(1)} ${rhythm(1.2)}`,
          ": hover": {
            boxShadow: "0 2px 3px 0 rgba(0,0,0,0.25)",
            cursor: "pointer",
          },
        }}
        /* onMouseEnter={() => setHoveredUnit(code)} */
        /* onMouseLeave={() => setHoveredUnit("")} */
      >
        <div
          css={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <small css={{ fontSize: "80%" }}>
            {tr("Incident")}: {code}
          </small>
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <small
              css={{
                paddingTop: "0.2rem",
                marginRight: "0.4rem",
                fontWeight: "500",
                fontSize: "80%",
              }}
            >
              {incident?.observations?.length} {tr("Observations")}{" "}
            </small>
            <MdPlayArrow
              css={{
                fontSize: "130%",
              }}
            />{" "}
          </div>
        </div>
        <h3
          css={{
            marginTop: rhythm(0.4),
          }}
        >
          {isAra ? title_ar : title_en}
        </h3>
        <div
          css={{
            display: "flex",
          }}
        >
          <div
            css={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "80%",
            }}
          >
            <FaCalendarMinus css={{ marginRight: "0.5rem" }} />{" "}
            {formatDate(incident_date, locale)}
          </div>
          <div
            css={{ fontSize: "80%", marginLeft: rhythm(1), display: "flex" }}
          >
            <TiLocation css={{ marginRight: "0.3rem" }} />
            {location}
          </div>
        </div>
      </div>
    </LocalizedLink>
  );
}
