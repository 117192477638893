import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import useIncidents from "../hooks/useIncidents";
import { DataContainer, colors } from "../style/theme";
import useLocale from "../hooks/useLocale";
import TableFilters from "../components/data/table-filters";
import Timeline from "../charts/timeline";
import IncidentCard from "../components/data/incidents-card";
import SEO from "../components/seo";
import Pagination from "rc-pagination";
import Map from "../components/map";
import "../style/pagination.css";
import { slice, head } from "ramda";

const PER_PAGE = 20;

export default function Data() {
  const { locale } = useLocale();
  const { incidents, setFilters, filters } = useIncidents();
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredUnit, setHoveredUnit] = useState("IIIII");

  return (
    <Layout>
      <SEO metadata={{ title: "Data" }} />
      <div css={{ backgroundColor: colors.backgroundColor }}>
        <DataContainer css={{ paddingTop: "4rem" }}>
          <Timeline
            lang={locale}
            incidents={incidents}
            width={1200}
            height={100}
            isLoading={false}
          />
        </DataContainer>
        <DataContainer css={{ display: "block" }}>
          <TableFilters
            setFilters={setFilters}
            filters={filters}
            setCurrentPage={setCurrentPage}
          />
          <small>{incidents.length} Results</small>
        </DataContainer>
        <DataContainer css={{ position: "relative" }}>
          <div>
            {slice(
              PER_PAGE * (currentPage - 1),
              PER_PAGE * (currentPage - 1) + PER_PAGE,
              incidents
            ).map((incident) => (
              <IncidentCard
                incident={incident}
                key={incident.incident_code}
                setHoveredUnit={setHoveredUnit}
              />
            ))}
          </div>
          <div
            css={{
              position: "sticky",
              top: "0",
              willChange: "transform",
              height: "100vh",
              boxSizing: "border-box",
            }}
          >
            <Map
              incidents={incidents}
              viewport={{
                lat: 35,
                lng: 38,
                zoom: 7,
              }}
            />
          </div>
        </DataContainer>
        <DataContainer>
          <Pagination
            current={currentPage}
            total={incidents.length}
            onChange={(current) => setCurrentPage(current)}
          />
        </DataContainer>
      </div>
    </Layout>
  );
}
